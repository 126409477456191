import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FaFacebookF, FaInstagram, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <div className="row ">
          <div className="col-lg-5 col-xl-6">
            <ul className="main-navigation list-unstyled">
              <li>
                <a href="#service">Serviços</a>
              </li>
              <li>
                <a href="#about">Sobre nós</a>
              </li>
              <li>
                <a href="#budget">Orçamento</a>
              </li>
              <li>
                <a href="#footer">Contato</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-7 col-xl-6">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <span className="title">Venha tomar um café!</span>
                  <p>
                    Rua Getúlio Zoby Junior <br /> Garanhuns, Pernambuco
                  </p>
                </address>
                <address className="address">
                  <span className="title">Contatos</span>
                  <a
                    href="mailto:suporte@kernelstudio.com.br"
                    className="tel"
                  >
                    <MdEmail /> suporte@kernelstudio.com.br
                  </a>
                </address>
              </div>
              <div className="contact-inner">
                <h5 className="title">Rede sociais</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=100083384748485">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/kernelsoftwarestudio/">
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
