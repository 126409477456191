import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import AboutFive from "../component/about/AboutFive";
import BannerOne from "../component/banner/BannerOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ServicePropOne from "../component/service/ServicePropOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import { AnimationOnScroll } from "react-animation-on-scroll";

const DigitalAgency = () => {
  return (
    <>
      <SEO title="Kernel | Software Studio" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerOne />
        <div id="service" className="section section-padding bg-color-dark">
          <div className="container">
            <AnimationOnScroll
              animateIn="slideInLeft"
              duration={1}
              delay={200}
              animateOnce={true}
            >
              <SectionTitle
                subtitle="Trabalhamos em estreita colaboração com nossos clientes"
                title="Nossos Serviços"
                description=""
                textAlignment="heading-light-left mb--20 mb_md--70"
                textColor="#f4f4f4f4"
              />
            </AnimationOnScroll>
            <div className="row">
              <ServicePropOne
                colSize="col-lg-4"
                serviceStyle="service-style-2"
                itemShow="3"
                marginTop="yes"
              />
            </div>
          </div>
          <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                alt="circle"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Line"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Line"
              />
            </li>
          </ul>
        </div>
        <AboutFive />
        <AnimationOnScroll
          animateIn="fadeInUp"
          duration={1}
          delay={200}
          animateOnce={true}
        >
          <CtaLayoutOne />
        </AnimationOnScroll>

        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default DigitalAgency;
