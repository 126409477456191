import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { AnimationOnScroll } from "react-animation-on-scroll";
const FooterOne = ({ parentClass }) => {
  return (
    <footer id="footer" className={`footer-area ${parentClass}`}>
      <div className="container">
        <div className="footer-top">
          <AnimationOnScroll
            animateIn="fadeInUp"
            duration={1}
            delay={200}
            animateOnce={true}
          >
            <div className="footer-social-link">
              <ul className="list-unstyled">
                <li></li>
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100083384748485">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/kernelsoftwarestudio/">
                    <FaInstagram />
                  </a>
                </li>
                <li></li>
              </ul>
            </div>
          </AnimationOnScroll>
        </div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <AnimationOnScroll
                  animateIn="slideInLeft"
                  duration={1}
                  delay={200}
                  animateOnce={true}
                >
                  <div className="footer-newsletter">
                    <h2 className="title">Vamos falar do seu projeto!</h2>
                    <p>
                      Compreendemos que cada empresa possui demandas distintas.
                      Por essa razão, gostaríamos de convidá-lo a compartilhar
                      detalhes sobre seu projeto, a fim de que possamos
                      desenvolver uma solução personalizada e alinhada às
                      necessidades únicas de sua empresa.
                    </p>
                    <a
                      href="https://rv0v965nbhh.typeform.com/to/EQfuAxCd"
                      className="axil-btn btn-fill-primary btn-large"
                    >
                      Fale conosco
                    </a>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="footer-newsletter">
                <AnimationOnScroll
                  animateIn="slideInRight"
                  duration={1}
                  delay={200}
                  animateOnce={true}
                >
                  <p>
                    <center>
                      <iframe
                        title="Localização"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3942.018859581298!2d-36.49483228459443!3d-8.877835793470483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7070cc4c41e4f5d%3A0x5599b81bf636b74d!2sR.%20Get%C3%BAlio%20Zoby%20J%C3%BAnior%20-%20S%C3%A3o%20Jos%C3%A9%2C%20Garanhuns%20-%20PE!5e0!3m2!1spt-BR!2sbr!4v1658176143886!5m2!1spt-BR!2sbr"
                        width="400"
                        height="300"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                      <br></br>
                    </center>
                  </p>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()} - Kernel Software Studio
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
