import React from "react";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li>
          <a href="#service">Serviços</a>
        </li>
        <li>
          <a href="#about">Sobre nós</a>
        </li>
        <li>
          <a href="#budget">Orçamento</a>
        </li>
        <li>
          <a href="#footer">Contato</a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
