import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const AboutFive = () => {
  return (
    <div id="about" className="section-padding-equal">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="about-team">
              <div className="thumbnail">
                <AnimationOnScroll
                  animateIn="slideInLeft"
                  duration={3}
                  delay={100}
                  animateOnce={true}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/images/about/about-2.png"}
                    alt="thumbnail"
                  />
                </AnimationOnScroll>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-team">
              <div className="section-heading heading-left">
                <AnimationOnScroll
                  animateIn="fadeInUp"
                  animateOnce={true}
                  delay={100}
                >
                  <span className="subtitle">Sobre nós</span>
                  <h2>
                    Transforme sua empresa com soluções tecnológicas inovadoras
                  </h2>

                  <p>
                    A kernel acredita na criação de projetos personalizados que
                    ultrapassam simplesmente a entrega de funcionalidades. Nós
                    nos esforçamos para fazer a diferença em sua empresa,
                    oferecendo soluções tecnológicas eficientes e inovadoras.
                    Não nos satisfazemos com o mínimo, nós queremos impactar
                    positivamente a vida de nossos clientes e ajudá-los a
                    alcançar seus objetivos de negócios.
                  </p>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
